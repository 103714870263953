<template>
  <div class="relative py-20 bg-white overflow-hidden px-4 sm:px-40">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
      <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
        <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
        </svg>
        <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
        </svg>
      </div>
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">

<!--        <p class="mt-8 text-xl text-gray-500 leading-8">Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend egestas fringilla sapien.</p>-->
      </div>
      <div class="mt-12 prose prose-indigo prose-lg text-gray-500 mx-auto text-left poente_font space-y-7 text-xl">
        <p>Poente Technical Academy is an independent EASA Part 147 maintenance training organization (EASA.147.0154) based in Belgrade, Serbia.  <a class="text-blue-500" target="_blank" href="www.pta.edu.rs">www.pta.edu.rs</a></p>
        <p>Our e-learning courses are available 24/7, so you can complete online your initial and continuation trainings immediately. When you are done, your digital certificate is issued and stored at your PTA account, available for download. </p>
        <p>We offer both initial and continuation trainings, performed in accordance with the approved training program by a maintenance training organization, approved to provide training and conduct examinations within its approval schedule and in accordance with Annex IV (Part-147) of Regulation (EC) No 1321/2014.</p>
        <img class="w-full xl:w-2/3 mx-auto rounded-2xl" src="/DSC_1046.jpg" />
      </div>
    </div>
  </div>
</template>