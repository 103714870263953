export default function (value, currency_code) {
    if (value === "") {
        return ""
    }
    let currency = 'RSD'
    if (currency_code) {
        currency = currency_code
    }
    const formated = new Intl.NumberFormat('en-DE', { style: 'currency', currency: currency == "KM" ? "BAM" : currency, maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value)
    let currency_split = currency == "EUR" ? "€" : currency == "KM" ? "BAM" : currency;
    return formated.split(currency_split)[1] + ' ' + currency;
}