<template>
  <div class="flex items-center justify-between">
    <div class="w-1/2 space-y-4">
      <div class="lg:w-8/12 relative flex items-center">
        <div class="absolute inset-y-0 left-0 flex py-2 pl-1.5">
          <search-icon class="text-gray-500 h-5 w-5"/>
        </div>
        <div class="w-full md:shadow-sm focus:ring-base-500 focus:border-base-500 block w-full sm:text-sm border-gray-100 rounded-md">
          <input v-model.lazy="search" v-debounce="400" type="text" name="search" id="search" :placeholder="loc.search_by_benefit_title"
                 class="w-full md:shadow-sm focus:ring-base-500 focus:border-base-500 block w-full pr-12 sm:text-sm border-gray-200 rounded-md pl-9"/>
        </div>
      </div>
      <div class="w-full flex items-center gap-x-4">
        <div class="w-1/3">
          <Menu as="div" class="relative w-full">
            <!--            <label for="location" class="block text-sm font-medium text-gray-700">{{ loc.category }}</label>-->
            <MenuButton :class="`focus:ring-2 ring-base-500 rounded-md shadow border w-full h-10 bg-white flex items-center justify-between text-gray-500 hover:text-base-600 relative px-2 ${$refs['categories']?.visible ? 'ring-2 ring-base-500' : ''}`">
              <p class="w-5/6 text-left truncate text-sm" :class="selected_category?.name ? 'text-black' : ''">{{ selected_category ? parse_name(selected_category?.name) : loc.category }}</p>
              <!--              <p v-if="$refs['categories']?.visible">test</p>-->
              <font-awesome-icon  icon="fa-chevron-down" :class="$refs['categories']?.visible ? 'transform -rotate-180 transition duration-200 linear' : 'transition duration-200 linear'" />
              <!--              <font-awesome-icon v-else icon="fa-chevron-up" />-->
            </MenuButton>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems ref="categories" class="origin-top-right absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 h-72 overflow-y-scroll">
                <div class="py-1">
                  <MenuItem v-slot="{ active }" v-for="category in categories" @click="$emit('onCategoryChange', category.id)">
                    <div class="flex px-2 py-2 border-b text-sm cursor-pointer hover:bg-gray-200 hover:text-base-600" :class="selected_category?.id === category.id ? 'bg-base-600 text-white font-medium': ''">
                      {{ parse_name(category.name) }}
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div class="w-1/3 border h-10"></div>
        <div class="w-1/3 border h-10"></div>
      </div>
    </div>
    <div class="w-1/2">test</div>
  </div>
</template>

<script>
import {SearchIcon} from '@heroicons/vue/solid';
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {mapGetters, mapState} from 'vuex';

export default {
  name: "Filters" ,
  data() {
    return {
     search: '',
    }
  },
  components: {SearchIcon, Menu, MenuButton, MenuItem, MenuItems},
  computed: {
    ...mapState('filters', ['categories']),
    ...mapGetters('filters', ['selected_category']),
  },
  methods: {

  },
}
</script>

<style scoped>

</style>